import {
  collection,
  doc,
  getFirestore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFirestoreRef } from "./firestore";
import { useAccountID } from "./user";

export function useLastScannedImages() {
  const account = useAccountID();
  const reference = useMemo(() => {
    if (!account) return null;

    const db = getFirestore();
    return query(
      collection(db, "images"),
      where("account_id", "in", [account]),
      where("last_exploitability_scan", "!=", null),
      orderBy("last_exploitability_scan", "desc")
    );
  }, [account]);

  return useFirestoreRef(reference);
}

export function useLastScanResult(imageId, type) {
  const account = useAccountID();
  const reference = useMemo(() => {
    if (!account) return null;

    const db = getFirestore();
    return query(
      collection(db, "scans"),
      where("account_id", "in", [account]),
      where("image", "==", imageId),
      where("engine", "==", type),
      orderBy("created_at", "desc"),
      limit(1)
    );
  }, [account, type, imageId]);

  const result = useFirestoreRef(reference);
  const lastResult = useMemo(() => {
    if (!result || result.length === 0) {
      return null;
    }

    return result[0];
  }, [result]);

  return lastResult;
}

export function useLastGrypeResult(imageId) {
  const type = useMemo(() => "grype", []);
  return useLastScanResult(imageId, type);
}

export function useLastTrivyResult(imageId) {
  const type = useMemo(() => "trivy", []);
  return useLastScanResult(imageId, type);
}

export function useLastExploitabilityResult(imageId) {
  const type = useMemo(() => "atom-crawler", []);
  return useLastScanResult(imageId, type);
}

function normalizePackageVersion(version) {
  if (!version) {
    return version;
  }

  version = version.toLowerCase();
  version = version.replace("-", ".");
  version = version.replace("_", ".");

  return version;
}

export function useVulnerabilities(imageId) {
  const grype = useLastGrypeResult(imageId);
  const trivy = useLastTrivyResult(imageId);
  const exploitability = useLastExploitabilityResult(imageId);

  const merged = useMemo(() => {
    let all = [];

    if (grype === null && trivy === null) {
      return null;
    }

    if (grype) {
      all = all.concat(grype.results);
    }

    if (trivy) {
      all = all.concat(trivy.results);
    }

    // De-duplicate the vulnerabilities.
    const deduped = all.reduce((acc, cur) => {
      if (
        acc.find(
          (item) =>
            item.cve === cur.cve &&
            item.packageName === cur.packageName &&
            item.packageVersion === cur.packageVersion
        )
      ) {
        return acc;
      }

      return acc.concat(cur);
    }, []);

    // Add the exploitability data.
    let results = deduped;
    if (exploitability) {
      results = results.map((item) => {
        let found;
        if (["debian", "deb", "dpkg"].includes(item.packageType)) {
          found = exploitability.packages.find((pkg) => {
            return (
              pkg.name === item.packageName &&
              pkg.version === item.packageVersion
            );
          });
        } else {
          found = exploitability.packages.find((pkg) => {
            if (["debian", "deb", "dpkg"].includes(item.packageType)) {
              return false;
            }
            return (
              normalizePackageVersion(item.packageVersion).match(
                normalizePackageVersion(pkg.version)
              ) ||
              normalizePackageVersion(pkg.version).match(
                normalizePackageVersion(pkg.packageVersion)
              )
            );
          });
        }

        return {
          ...item,
          id: `${item.packageName}@${item.packageVersion}@${item.cve}`,
          exploitable: found,
        };
      });
    }

    return results;
  }, [grype, trivy, exploitability]);

  return merged;
}

export const useWithVulnerabilitiesWithEPSS = (imageId) => {
  const [results, setResults] = useState([]);
  const vulnerabilities = useVulnerabilities(imageId);

  useEffect(() => {
    if (!vulnerabilities || vulnerabilities.length === 0) {
      setResults(vulnerabilities);
      return;
    }

    let output = vulnerabilities.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    const cves = vulnerabilities.reduce((acc, cur) => {
      acc[cur.cve] = true;
      return acc;
    }, {});

    const ids = Object.keys(cves);

    httpsCallable(
      getFunctions(),
      "getEPSS"
    )({ cves: ids })
      .then((result) => {
        const results = result.data.data;
        for (const result of results) {
          for (const v of Object.values(output)) {
            if (v.cve === result.cve) {
              output[v.id]["epss"] = result["percentile"];
            }
          }
        }
        setResults(Object.values(output));
      })
      .catch((error) => {
        console.log(error);
        setResults(vulnerabilities);
      });
  }, [vulnerabilities]);

  return results;
};
