import React, { useCallback } from "react";
import logo from "../resources/atom-text-white.png";

export function WithTopBar({ children }) {
  return (
    <div style={styles.Wrap}>
      <div style={styles.TopBar}>
        <img src={logo} style={styles.Logo} alt="logo" />
        <div style={styles.Beta}>Early Access</div>
        <div style={{ flex: 1 }} />
      </div>
      <div style={styles.Content}>{children}</div>
    </div>
  );
}

const styles = {
  Wrap: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  TopBar: {
    height: "3rem",
    width: "100%",
    borderBottom: "1px solid #444",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  Content: {
    flex: 1,
    overflow: "hidden",
  },
  Logo: {
    marginLeft: "1rem",
    height: "38%",
    width: "auto",
  },
  Beta: {
    marginLeft: ".5rem",
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "#1dd1a1",
    cursor: "default",
  },
};
