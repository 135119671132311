import React from "react";
import { CircularProgress, Tooltip } from "@mui/material";

export const EPSSView = React.memo(({ precentile }) => {
  precentile = precentile ?? 0;
  let actualValue = Math.round(precentile * 100) / 100;
  let progressValue = Math.round(precentile * 100);
  let value = Math.round(precentile * 10);
  if (value <= 1) {
    value = 0;
    progressValue = 0;
  }

  if (value >= 9) {
    value = "🔥";
    progressValue = 0;
  }

  return (
    <Tooltip title={`Exploited in the Wild: ${actualValue}`} placement="right">
      <div style={styles.EPSSView}>
        {value !== "🔥" && (
          <>
            {" "}
            <CircularProgress
              style={{ ...styles.EPSSViewProgress, color: "#313640" }}
              size={27}
              thickness={4}
              color="error"
              variant="determinate"
              value={100}
            />
            <CircularProgress
              style={styles.EPSSViewProgress}
              size={27}
              thickness={4}
              variant="determinate"
              value={progressValue}
            />
          </>
        )}
        <div style={styles.EPSSViewText}>{value}</div>
      </div>
    </Tooltip>
  );
});

const styles = {
  EPSSView: {
    position: "relative",
    display: "flex",
    alighItems: "center",
    justifyContent: "center",
  },
  EPSSViewProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "#ff6b6b",
  },
  EPSSViewText: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
};
