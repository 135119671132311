import { useMemo } from "react";
import { Tooltip } from "@mui/material";

export const SEVERITY_RANK = {
  Unknown: 0,
  Negligible: 1,
  Low: 2,
  Medium: 3,
  High: 4,
  Critical: 5,
};

export const SEVERITY_COLOR = {
  Unknown: "#6ba6ef",
  Negligible: "#1dd1a1",
  Low: "#feca57",
  Medium: "#ff9f43",
  High: "#ff6b6b",
  Critical: "#ca76ff",
};

export function SeverityView({ style, severity, count }) {
  const text = useMemo(() => {
    if (count !== undefined) {
      return count;
    }

    return severity[0];
  }, [severity, count]);

  const styling = useMemo(() => {
    return {
      ...styles.Default,
      ...style,
      ...(0 === count
        ? styles.Irrelevant
        : severity === "Unknown"
        ? styles.Unknown
        : severity === "Negligible"
        ? styles.Negligible
        : severity === "Low"
        ? styles.Low
        : severity === "Medium"
        ? styles.Medium
        : severity === "High"
        ? styles.High
        : severity === "Critical"
        ? styles.Critical
        : styles.Unknown),
    };
  }, [severity, style, count]);

  return (
    <Tooltip title={severity}>
      <div style={styling}>{text}</div>
    </Tooltip>
  );
}

const styles = {
  Default: {
    width: "1.2rem",
    height: "1.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.25rem",
    cursor: "default",
  },
  Unknown: {
    backgroundColor: "#6ba6ef",
  },
  Negligible: {
    backgroundColor: "#1dd1a1",
  },
  Low: {
    backgroundColor: "#feca57",
  },
  Medium: {
    backgroundColor: "#ff9f43",
  },
  High: {
    backgroundColor: "#ff6b6b",
  },
  Critical: {
    backgroundColor: "#ca76ff",
  },
  Irrelevant: {
    backgroundColor: "#313640",
    color: "#4c515f",
  },
};
