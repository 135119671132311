import { useCallback, useMemo } from "react";
import { SeverityView } from "./severity-view";
import { EPSSView } from "./epss-view";

export function VulnerabilityView({ vulnerability }) {
  const link = useMemo(() => {
    return `https://nvd.nist.gov/vuln/detail/${vulnerability.cve}`;
  }, [vulnerability.cve]);

  const openLink = useCallback(() => {
    window.open(link, "_blank");
  }, [link]);

  return (
    <div style={styles.VulnerabilityView}>
      <div style={styles.VulnerabilityViewSeverity}>
        <SeverityView severity={vulnerability.severity} />
      </div>
      <div style={styles.VulnerabilityViewCve}>
        <div onClick={openLink} style={styles.Link}>
          {vulnerability.cve}
        </div>
      </div>
      <div style={styles.VulnerabilityViewExploitability}>
        <EPSSView precentile={vulnerability.epss} />
      </div>
    </div>
  );
}

const styles = {
  VulnerabilityView: {
    display: "flex",
    flexDirection: "row",
    height: "2rem",
    padding: "0.2rem 0.5rem",
    alignItems: "center",
    borderTop: "1px solid #313640",
  },
  VulnerabilityViewCve: {
    flex: 1,
    cursor: "pointer",
  },
  VulnerabilityViewSeverity: {
    marginRight: "0.5rem",
  },
  VulnerabilityViewExploitability: {
    width: "2rem",
  },
};
