// A react component that renders the login screen
import { CircularProgress } from "@mui/material";
import React from "react";
import { Flex } from "../components/flex";

export const LoadingScreen = React.memo(() => {
  return (
    <Flex style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <CircularProgress size={50} />
    </Flex>
  );
});
