import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useEffect, useState } from "react";
import { createAPIKey, deleteAPIKey, getAPIKeys } from "../lib/apikeys";
import { LoadingScreen } from "../screens/loading-screen";
import { Item } from "./menu";
// other @mui imports...

export function APIKeysView() {
  const [disabled, setDisabled] = useState(false);
  const [keys, setKeys] = useState(null);

  useEffect(() => {
    let cancel = false;

    getAPIKeys().then((keys) => {
      if (cancel) return;
      setKeys(keys);
    });

    return () => (cancel = true);
  }, []);

  const handleDelete = useCallback(async (key) => {
    setDisabled(true);
    try {
      await deleteAPIKey(key);
      setKeys((keys) => keys.filter((k) => k !== key));
    } finally {
      setDisabled(false);
    }
  }, []);

  const handleCreate = useCallback(async () => {
    setDisabled(true);
    try {
      const apiKey = await createAPIKey();
      setKeys([...keys, apiKey]);
    } finally {
      setDisabled(false);
    }
  }, [keys]);

  return (
    <div style={styles.View}>
      {keys === null ? (
        <div style={styles.Item}>
          <LoadingScreen />
        </div>
      ) : (
        keys.map((key) => (
          <div style={styles.Item} key={key}>
            <div style={styles.ItemKey}>{key}</div>
            <div style={styles.ItemValue}>
              {/* Delete button */}
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => handleDelete(key)}
                disabled={disabled}
              >
                Delete
              </Button>
            </div>
          </div>
        ))
      )}
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleCreate}
        disabled={disabled}
      >
        Generate New API Key
      </Button>
    </div>
  );
}

const styles = {
  View: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    maxHeight: "100%",
  },
  Item: {
    minWidth: "30rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
    backgroundColor: "#313640",
    padding: "0.5rem",
    borderRadius: "0.2rem",
  },
  ItemKey: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginRight: "1rem",
  },
  ItemValue: {
    fontSize: "1rem",
  },
};
