import {
  collection,
  getFirestore,
  orderBy,
  query,
  where,
  serverTimestamp,
  addDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useMemo } from "react";
import { useFirestoreRef } from "./firestore";
import { useAccountID } from "./user";

export function useAccountRegistries() {
  const account = useAccountID();
  const reference = useMemo(() => {
    if (!account) return null;

    const db = getFirestore();
    return query(
      collection(db, "registries"),
      where("account_id", "==", account)
    );
  }, [account]);

  return useFirestoreRef(reference);
}

export async function createRegistry(
  account,
  type,
  authority,
  username,
  token
) {
  const db = getFirestore();
  const result = await addDoc(collection(db, "registries"), {
    account_id: account,
    created_at: serverTimestamp(),
    type,
    authority,
    username,
    token,
  });

  return result.id;
}

export async function deleteRegistry(id) {
  const db = getFirestore();
  const ref = doc(collection(db, "registries"), id);
  await deleteDoc(ref);
}
