import { useAccountID, useAccountInfo } from "../lib/user";

export function AccountView() {
  const account = useAccountID();
  const accountInfo = useAccountInfo();

  return (
    <div style={styles.AccountView}>
      <div style={styles.Item}>
        <div style={styles.ItemKey}>Account Name</div>
        <div style={styles.ItemValue}>{accountInfo?.name}</div>
      </div>
      <div style={styles.Item}>
        <div style={styles.ItemKey}>Account ID</div>
        <div style={styles.ItemValue}>{account}</div>
      </div>
    </div>
  );
}

const styles = {
  AccountView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
  Item: {
    width: "30rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
    backgroundColor: "#313640",
    padding: "0.5rem",
    borderRadius: "0.2rem",
  },
  ItemKey: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginRight: "1rem",
  },
  ItemValue: {
    fontSize: "1rem",
  },
};
