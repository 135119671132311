import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { WithMenu } from "../components/menu";
import { WithTopBar } from "../components/topbar";
import {
  createRegistry,
  deleteRegistry,
  useAccountRegistries,
} from "../lib/registry";
import { useAccountID } from "../lib/user";

// List of registry services
const registryServices = [
  {
    type: "jfrog",
    label: "JFrog Articatory",
    logo: "https://miro.medium.com/v2/resize:fit:400/1*klTJGBP5RDXmK2U2_xp8Ow.png",
    enabled: true,
    pattern: "<authority>.jfrog.io",
  },
  // Add more registry services as needed
];

const ConnectedRegistries = () => {
  const [disabled, setDisabled] = useState(false);
  const registries = useAccountRegistries();
  const registryMap = useMemo(() => {
    const map = {};
    registryServices?.forEach((registry) => {
      map[registry.type] = registry;
    });
    return map;
  }, []);

  const handleDisconnect = useCallback((registry) => {
    setDisabled(true);
    deleteRegistry(registry.id).finally(() => setDisabled(false));
  }, []);

  return (
    <div>
      <div style={styles.Title}>Connected Registries</div>
      {registries?.length === 0 && (
        <div style={styles.Empty}>No registries connected</div>
      )}
      {registries?.map((registry, index) => (
        <div key={index} style={styles.Registry}>
          <img
            style={styles.RegistryIcon}
            src={registryMap[registry.type].logo}
            alt="logo"
          />
          <div style={styles.RegistryInfo}>
            <div style={styles.RegistryName}>{registry.authority}</div>
            <div style={styles.RegistryAuthority}>@{registry.username}</div>
          </div>
          <div style={{ flex: 1 }} />
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleDisconnect(registry)}
            disabled={disabled}
          >
            Disconnect
          </Button>
        </div>
      ))}
      <br />
      <br />
    </div>
  );
};

const AddNewRegistry = ({
  registry,
  authority,
  username,
  token,
  setRegistry,
  setAuthority,
  setUsername,
  setToken,
  handleSubmit,
}) => {
  const [authorityName, setAuthorityName] = useState("");
  const authorityURL = useMemo(() => {
    const service = registryServices.find((i) => i.type === registry);
    if (service) {
      return service.pattern.replace(
        "<authority>",
        authorityName || "<authority>"
      );
    }
    return "";
  }, [registry, authorityName]);

  useEffect(() => {
    setAuthority(authorityURL);
  }, [authorityURL, setAuthority]);

  return (
    <div>
      <div style={styles.Title}>Add New Registry</div>
      <form onSubmit={handleSubmit} style={styles.Form}>
        <FormControl fullWidth>
          <InputLabel id="registry-label">Registry</InputLabel>
          <Select
            labelId="registry-label"
            value={registry}
            onChange={(e) => setRegistry(e.target.value)}
            size="small"
            style={styles.Input}
          >
            {registryServices
              .filter((i) => i.enabled)
              .map((service, index) => (
                <MenuItem key={index} value={service.type}>
                  <img
                    style={styles.RegistryIconSelect}
                    src={service.logo}
                    alt={service.label}
                    height="20"
                  />{" "}
                  {service.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Authority"
          value={authorityName}
          onChange={(e) => setAuthorityName(e.target.value)}
          size="small"
          style={styles.Input}
        />
        <TextField
          fullWidth
          label="Authority URL"
          disabled
          value={authorityURL}
          size="small"
          style={styles.Input}
        />
        <TextField
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          size="small"
          style={styles.Input}
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          type="password"
          label="Token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          size="small"
          style={styles.Input}
        />
        <Button size="small" variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export const RegistriesScreen = React.memo(() => {
  const account = useAccountID();
  const [registry, setRegistry] = useState("jfrog");
  const [authority, setAuthority] = useState("");
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");
  const [formError, setFormError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Validate the form inputs
    if (!registry || !authority || !username || !token) {
      setFormError("Please fill out all fields.");
    } else {
      setFormError("");
      createRegistry(account, registry, authority, username, token);
      setRegistry("");
      setAuthority("");
      setUsername("");
      setToken("");
    }
  };

  return (
    <WithMenu>
      <WithTopBar>
        <div style={styles.Screen}>
          <ConnectedRegistries />
          <AddNewRegistry
            registry={registry}
            authority={authority}
            username={username}
            token={token}
            setRegistry={setRegistry}
            setAuthority={setAuthority}
            setUsername={setUsername}
            setToken={setToken}
            handleSubmit={handleSubmit}
          />
          {formError && <div style={styles.Error}>Error: {formError}</div>}
        </div>
      </WithTopBar>
    </WithMenu>
  );
});

const styles = {
  Screen: {
    padding: "1rem",
  },
  Title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  Error: {
    color: "red",
    marginTop: "0.5rem",
  },
  RegistryIconSelect: {
    marginRight: "0.5rem",
  },
  Input: {
    marginBottom: "1rem",
  },
  Form: {
    width: "30rem",
  },
  AuthorityText: {
    fontSize: "0.8rem",
    marginTop: "0.5rem",
    marginBottom: "1rem",
  },
  Registry: {
    display: "flex",
    alignItems: "center",
    maxWidth: "40rem",
    flexDirection: "row",
    marginBottom: "0.5rem",
    backgroundColor: "#313640",
    padding: "0.5rem",
  },
  RegistryIcon: {
    marginRight: "0.5rem",
    height: "1.5rem",
  },
  RegistryInfo: {
    display: "flex",
    flexDirection: "column",
  },
  RegistryName: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  RegistryAuthority: {
    fontSize: "0.8rem",
  },
};

export default RegistriesScreen;
