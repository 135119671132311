import {
  BrowserRouter,
  useRoutes,
  Navigate,
  Router,
  Routes,
  Route,
} from "react-router-dom";
import { LoadingScreen } from "./screens/loading-screen";
import { HomeScreen } from "./screens/home-screen";
import { LoginScreen } from "./screens/login-screen";

// --------------------------------------------------------------------------------------------
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { useAccountID } from "./lib/user";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { SettingsScreen } from "./screens/settings-screen";
import { RegistriesScreen } from "./screens/registries-screen";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbh5JuxFTydt5wFldHYkHxMgTmUx3XHKY",
  authDomain: "atom-security.firebaseapp.com",
  projectId: "atom-security",
  storageBucket: "atom-security.appspot.com",
  messagingSenderId: "801266321073",
  appId: "1:801266321073:web:7dc8e1231d8e60b41cc490",
};

// Initialize Firebase
initializeApp(firebaseConfig);
// --------------------------------------------------------------------------------------------

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const loggedIn = useAccountID();
  const loading = loggedIn === null;

  return (
    <div style={styles.App}>
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Routes>
            {loading && <Route path="*" element={<LoadingScreen />} />}
            {!loading && !loggedIn && (
              <Route path="/login" element={<LoginScreen />} />
            )}
            {!loading && loggedIn && (
              <Route path="/" element={<HomeScreen />} />
            )}
            {!loading && loggedIn && (
              <Route path="/settings" element={<SettingsScreen />} />
            )}
            {!loading && loggedIn && (
              <Route path="/registries" element={<RegistriesScreen />} />
            )}
            {!loading && (
              <Route
                path="*"
                element={<Navigate to={loggedIn ? "/" : "/login"} replace />}
              />
            )}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;

const styles = {
  App: {
    backgroundColor: "#282c34",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    color: "white",
  },
};
