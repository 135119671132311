import { getFunctions, httpsCallable } from "firebase/functions";

export async function checkLoginAllowed(email) {
  const result = await httpsCallable(
    getFunctions(),
    "checkLoginAllowed"
  )({
    email,
  });

  return result.data;
}

export async function getAPIKeys() {
  const result = await httpsCallable(getFunctions(), "getAPIKeys")();
  const keys = result.data;

  if (!keys) {
    throw new Error("Could not get API keys");
  }

  return keys;
}

export async function createAPIKey() {
  const result = await httpsCallable(getFunctions(), "createAPIKey")();
  const key = result.data;

  if (!key) {
    throw new Error("Could not get API keys");
  }

  return key;
}

export async function deleteAPIKey(key) {
  const result = await httpsCallable(
    getFunctions(),
    "deleteAPIKey"
  )({
    key,
  });

  if (!result.data) {
    throw new Error("Could not delete API key");
  }
}
