import { getAuth, signInWithCustomToken } from "firebase/auth";
import { collection, doc, getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import { useFirestoreRef } from "./firestore";

export async function checkLoginAllowed(email) {
  const result = await httpsCallable(
    getFunctions(),
    "checkLoginAllowed"
  )({
    email,
  });

  return result.data;
}

export async function finishLogin() {
  const result = await httpsCallable(getFunctions(), "getLoginToken")();
  const token = result.data;

  if (!token) {
    throw new Error("Could not get login");
  }

  await signInWithCustomToken(getAuth(), token);
}

export function useCurrentUser() {
  const [user, setUser] = useState(getAuth().currentUser);

  useEffect(() => {
    let cancel = false;

    getAuth().onAuthStateChanged((user) => {
      if (cancel) return;

      if (!user) {
        user = false;
      }
      setUser(user);
    });

    return () => (cancel = true);
  }, []);

  return user;
}

export function useAccountID() {
  const [key, setKey] = useState(null);

  useEffect(() => {
    let cancel = false;

    getAuth().onIdTokenChanged((user) => {
      if (!user) {
        setKey(false);
        return;
      }

      user.getIdTokenResult().then((token) => {
        if (cancel) return;
        setKey(token.claims.account);
      });
    });

    return () => (cancel = true);
  }, []);

  return key;
}

export function useAccountInfo() {
  const accountId = useAccountID();
  const reference = useMemo(() => {
    if (!accountId) return null;

    const db = getFirestore();
    return doc(db, "accounts", accountId);
  }, [accountId]);

  return useFirestoreRef(reference);
}
