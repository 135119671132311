import React from "react";
import { WithTopBar } from "../components/topbar";
import { WithMenu } from "../components/menu";
import { ScanViewList } from "../components/scan-view";
import { useLastScannedImages } from "../lib/image";

export const HomeScreen = React.memo(() => {
  const images = useLastScannedImages();

  return (
    <WithMenu>
      <WithTopBar>
        <div style={styles.HomeScreen}>
          <ScanViewList images={images ?? []} />
        </div>
      </WithTopBar>
    </WithMenu>
  );
});

const styles = {
  HomeScreen: {
    flex: 1,
    display: "flex",
    maxHeight: "100%",
  },
};
