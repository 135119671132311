import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import RelativeDate from "./relative-date";
import {
  useLastExploitabilityResult,
  useLastGrypeResult,
  useLastTrivyResult,
  useVulnerabilities,
} from "../lib/image";
import { ProgrammingLanguages } from "./programming-languages";
import { Flex } from "./flex";
import { PackagesView } from "./packages-view";
import { Tooltip } from "@mui/material";

export function ScanViewList({ images }) {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (images.length > 0 && selectedImage === null) {
      setSelectedImage(images[0]);
    }
  }, [images, selectedImage]);

  return (
    <div style={styles.ScanViewListWrap}>
      <div style={styles.ScanViewList}>
        <ScanViewHeader />
        {images.map((image) => (
          <ScanView
            key={image.id}
            image={image}
            selected={selectedImage?.id === image.id}
            onSelect={() => setSelectedImage(image)}
          />
        ))}
      </div>
      <ScanDetails image={selectedImage} />
    </div>
  );
}

export function ScanViewHeader() {
  return (
    <div style={styles.ScanViewHeader}>
      <Tooltip title="Time of the last scan." placement="bottom-start">
        <div style={styles.LastScan}>Last Scan</div>
      </Tooltip>
      <Tooltip
        title="Name of the image as it appears in the registry."
        placement="bottom-start"
      >
        <div style={styles.ImageName}>Image</div>
      </Tooltip>
      <Tooltip
        title="Grype: an Open Source vulnerability scanner maintained by Anchore."
        placement="bottom-start"
      >
        <div style={styles.VulnerabilityScan}>Grype</div>
      </Tooltip>
      <Tooltip
        title="Trivy: an Open Source vulnerability scanner maintained by Aqua."
        placement="bottom-start"
      >
        <div style={styles.VulnerabilityScan}>Trivy</div>
      </Tooltip>
      <Tooltip
        title="Total de-duplicated number of vulnerabilities found by scanners."
        placement="bottom-start"
      >
        <div style={styles.VulnerabilityTotalHeader}>Agg.</div>
      </Tooltip>
      <Tooltip
        title="Total number of vulnerabilities that has an exploitation path."
        placement="bottom-start"
      >
        <div style={styles.VulnerabilityActual}>Actual</div>
      </Tooltip>
    </div>
  );
}

export function ScanView({ image, selected, onSelect }) {
  const [isHovered, setIsHovered] = useState(false);
  const lastGrypeResult = useLastGrypeResult(image.id);
  const countGrypeResults = useMemo(
    () => (lastGrypeResult ? lastGrypeResult.results.length : null),
    [lastGrypeResult]
  );
  const lastTrivyResult = useLastTrivyResult(image.id);
  const countTrivyResults = useMemo(
    () => (lastTrivyResult ? lastTrivyResult.results.length : null),
    [lastTrivyResult]
  );
  const total = useVulnerabilities(image.id);

  const actual = useMemo(() => {
    if (!total) {
      return null;
    }

    // count vulnerabilities that have an exploit path
    return total.filter((item) => item.exploitable);
  }, [total]);

  const title = useMemo(() => {
    let title = image.name || "unknown";
    let parts = title.split("/");
    if (parts.length === 1) {
      return title;
    }

    return parts[parts.length - 2] + "/" + parts[parts.length - 1];
  }, [image.name]);

  const handleClick = useCallback(() => {
    if (onSelect) {
      onSelect();
    }
  }, [onSelect]);

  return (
    <div
      style={
        selected
          ? { ...styles.ScanView, backgroundColor: "#414750" }
          : isHovered
          ? { ...styles.ScanView, backgroundColor: "#414750" }
          : styles.ScanView
      }
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={styles.LastScan}>
        <RelativeDate date={image.last_exploitability_scan} />
      </div>
      <div style={styles.ImageName}>{title.slice(-30)}</div>
      <div style={styles.VulnerabilityScan}>{countGrypeResults}</div>
      <div style={styles.VulnerabilityScan}>{countTrivyResults}</div>
      <div style={styles.VulnerabilityTotal}>
        <span className="slant-line">{total ? total.length : null}</span>
      </div>
      <div style={styles.VulnerabilityActual}>
        {actual ? actual.length : null}
      </div>
    </div>
  );
}

export function ScanDetails({ image }) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [image]);

  if (!image) {
    return <div style={styles.ScanDetails} />;
  }

  return (
    <div style={styles.ScanDetails} ref={ref}>
      <Flex style={{ justifyContent: "space-between" }}>
        <div style={styles.ScanDetailsTitle}>{image.name?.slice(-30)}</div>
        <ProgrammingLanguages languages={image.languages} />
      </Flex>
      <div style={styles.ScanDetailsHash}>{image.id}</div>
      <PackagesView image={image} />
    </div>
  );
}

const styles = {
  ScanDetails: {
    flex: 1,
    width: "50%",
    fontSize: "0.9rem",
    marginLeft: "auto",
    padding: "1rem",
    margin: ".5rem",
    backgroundColor: "#313640",
    overflowY: "auto",
  },
  ScanDetailsTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  ScanDetailsHash: {
    fontSize: "0.7rem",
    color: "white",
    display: "inline-block",
    backgroundColor: "#414750",
    borderRadius: "0.5rem",
    padding: "0.2rem 0.5rem",
    margin: "1rem 0",
    fontWeight: "600",
  },
  ScanView: {
    height: "3rem",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#313640",
    margin: "0.5rem 0",
    transition: "background-color 0.2s", // Add a transition for smooth effect
    cursor: "default", // Show pointer cursor on hover
  },
  ScanViewHeader: {
    height: "3rem",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0.5rem 0",
    cursor: "default", // Show pointer cursor on hover
  },
  LastScan: {
    marginLeft: "1.5rem",
    width: "6rem",
    color: "#d9d9d9",
    fontSize: "0.8rem",
  },
  ImageName: {
    flex: 1,
    fontWeight: "bold",
  },
  VulnerabilityScan: {
    width: "4rem",
  },
  VulnerabilityTotal: {
    width: "4rem",
    fontWeight: "bold",
    color: "#fff",
  },
  VulnerabilityTotalHeader: {
    width: "4rem",
    fontWeight: "bold",
    color: "#fff",
  },
  VulnerabilityActual: {
    width: "4rem",
    fontWeight: "bold",
    color: "#ff6b6b",
  },
  ScanViewList: {
    flex: 1,
    width: "50%",
    fontSize: "0.9rem",
    overflowY: "auto",
  },
  ScanViewListWrap: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
};
