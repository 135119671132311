import React from "react";
import moment from "moment";

const RelativeDate = ({ date }) => {
  const formattedDate = moment(date).fromNow();

  return <span>{formattedDate}</span>;
};

export default RelativeDate;
