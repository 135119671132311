import { Tooltip } from "@mui/material";
import { useMemo } from "react";

const LOGO_MAPPING = {
  c: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/The_C_Programming_Language_logo.svg/200px-The_C_Programming_Language_logo.svg.png",
  "c++":
    "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/200px-ISO_C%2B%2B_Logo.svg.png",
  "c#": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/C_Sharp_wordmark.svg/200px-C_Sharp_wordmark.svg.png",
  go: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Go_Logo_Blue.svg/200px-Go_Logo_Blue.svg.png",
  java: "https://cdn-icons-png.flaticon.com/512/226/226777.png",
  javascript:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/200px-Unofficial_JavaScript_logo_2.svg.png",
  kotlin:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin-logo.svg/200px-Kotlin-logo.svg.png",
  php: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/200px-PHP-logo.svg.png",
  python:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/200px-Python-logo-notext.svg.png",
  ruby: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Ruby_logo.svg/200px-Ruby_logo.svg.png",
  rust: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Rust_programming_language_black_logo.svg/200px-Rust_programming_language_black_logo.svg.png",
  swift:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Swift_logo.svg/200px-Swift_logo.svg.png",
  typescript:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/200px-Typescript_logo_2020.svg.png",
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
export function ProgrammingLanguages({ languages }) {
  const actualLanguages = useMemo(() => {
    if (languages.length === 0) {
      return ["c"];
    }
    return languages;
  }, [languages]);

  return (
    <div style={styles.Languages}>
      {actualLanguages.map((language) => {
        return (
          <Tooltip
            key={language}
            title={`${capitalize(language)} code detected.`}
          >
            <img
              alt={LOGO_MAPPING[language]}
              src={LOGO_MAPPING[language]}
              style={styles.Language}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}

const styles = {
  EntrypointTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    margin: "0.5rem 0",
  },
  Languages: {
    display: "flex",
    flexDirection: "row",
  },
  Language: {
    height: "1rem",
    width: "auto",
    margin: "0.2rem",
  },
};
