// A react component that renders the login screen
import React from "react";
import { Flex } from "../components/flex";
import { WithMenu } from "../components/menu";
import { WithTopBar } from "../components/topbar";
import { AccountView } from "../components/account-view";
import { APIKeysView } from "../components/apikeys-view";

export const SettingsScreen = React.memo(() => {
  return (
    <WithMenu>
      <WithTopBar>
        <div style={styles.SettingsScreen}>
          <div style={styles.Title}>Account</div>
          <AccountView />
          <br />
          <br />
          <div style={styles.Title}>API Keys</div>
          <APIKeysView />
        </div>
      </WithTopBar>
    </WithMenu>
  );
});

const styles = {
  SettingsScreen: {
    padding: "1rem",
  },
  Title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
};
