import { onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";

function firestoreDefaultParseResponse(response) {
  if (response.query) {
    return response.docs.map((doc) => doc);
  }

  return response;
}

function firestoreDefaultParseDocument(document) {
  let data = document.data();
  for (let key in data) {
    if (data[key] && data[key].toDate) {
      data[key] = data[key].toDate();
    }
  }

  data.id = document.id;
  return data;
}

export function useFirestoreRef(
  reference,
  parse_doc = firestoreDefaultParseDocument
) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!reference) return;
    let cancel = false;

    onSnapshot(reference, (snapshot) => {
      if (cancel) return;
      const data = firestoreDefaultParseResponse(snapshot);
      if (data) {
        if (data.length !== undefined) {
          setData(data.map((doc) => parse_doc(doc)));
        } else {
          setData(parse_doc(data));
        }
      } else {
        setData(false);
      }
    });

    return () => {
      cancel = true;
      setData(null);
    };
  }, [reference, parse_doc]);

  return data;
}
