import React, { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  getAuth,
} from "firebase/auth";
import logo from "../resources/logo-white.png";
import { TextField, Button } from "@mui/material";
import { LoadingScreen } from "./loading-screen";
import { checkLoginAllowed, finishLogin } from "../lib/user";

const Flex = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const Logo = styled("img")({
  height: "10rem",
  width: "auto",
  margin: "2rem",
});

export const LoginScreen = React.memo(() => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const signIn = useCallback(async () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };

    try {
      setLoading(true);
      setError(null);

      // Check login is allowed
      const allowed = await checkLoginAllowed(email);
      if (!allowed) {
        setError("You are not allowed to login.");
        return;
      }

      // Send link
      await sendSignInLinkToEmail(getAuth(), email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      setSent(true);
    } catch (e) {
      setLoading(false);
      setError("Error has occured, please contact our support");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [email]);

  useEffect(() => {
    const cleanURL = () => {
      const url = window.location.href;
      const cleanUrl = url.split("?")[0];

      // Redirect to the clean URL
      window.location.href = cleanUrl;
    };

    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      setLoading(true);
      let email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }

      if (email) {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem("emailForSignIn");
            finishLogin()
              .then((result) => {
                cleanURL();
              })
              .catch((e) => {
                console.error(e);
                setError("Error has occured, please contact our support");
              });
          })
          .catch((e) => {
            setError("Error has occured, please contact our support");
            console.error(e);
            setLoading(false);
          });
      }
    }
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Flex style={styles.LoginScreen}>
      <Logo src={logo} alt="Company logo" />
      <Flex style={{ flexDirection: "row" }}>
        <TextField
          disabled={sent}
          style={styles.TextField}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Enter your email"
          variant="outlined"
          size="small"
        />
        <Button
          disabled={sent}
          size="medium"
          variant="contained"
          onClick={signIn}
        >
          Send Link to Email
        </Button>
      </Flex>
      {error && <p style={styles.Error}>{error}</p>}
      {sent && <p>Email Sent 👍 Check your email for a link to sign in</p>}
    </Flex>
  );
});

const styles = {
  LoginScreen: {
    flex: 1,
    alignItems: "center",
  },
  TextField: {
    marginRight: ".5rem",
    width: "15rem",
  },
  Error: {
    color: "#FF7676",
  },
};
