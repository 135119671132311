import React, { useMemo } from "react";

export const Flex = ({ children, direction, flex, ...props }) => {
  const style = useMemo(() => {
    let s = {};
    Object.assign(s, styles.flex);
    Object.assign(s, props.style);

    if (direction) {
      s.flexDirection = direction;
    }
    if (flex) {
      s.flex = flex;
    }
    return s;
  }, [direction, flex, props.style]);

  return (
    <div {...props} style={style}>
      {children}
    </div>
  );
};

const styles = {
  flex: {
    display: "flex",
  },
};
