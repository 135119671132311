import { useCallback, useMemo, useState } from "react";
import {
  useLastExploitabilityResult,
  useWithVulnerabilitiesWithEPSS,
} from "../lib/image";
import { VulnerabilityView } from "./vulnerability-view";
import { SEVERITY_RANK, SeverityView } from "./severity-view";
import { LoadingScreen } from "../screens/loading-screen";
import { Flex } from "./flex";
import { ExploitPathView } from "./exploit-path-view";

export function PackagesView({ image }) {
  const vulnerabilities = useWithVulnerabilitiesWithEPSS(image?.id || null);
  const lastExplResult = useLastExploitabilityResult(image?.id || null);
  const exploitPackages = useMemo(() => {
    if (!lastExplResult) {
      return {};
    }

    const results = {};
    for (let pkg of lastExplResult.packages) {
      results[`${pkg.name}@${pkg.version}`] = pkg;
    }

    return results;
  }, [lastExplResult]);

  const [exploitable, unexploitable] = useMemo(() => {
    if (!vulnerabilities) {
      return [[], []];
    }

    let packages = {};

    for (let vulnerability of vulnerabilities) {
      const packageId = `${vulnerability.packageName}@${vulnerability.packageVersion}`;
      if (packages[packageId]) {
        packages[packageId].vulnerabilities.push(vulnerability);
        packages[packageId].exploitable =
          packages[packageId].exploitable || vulnerability.exploitable;
      } else {
        packages[packageId] = {
          id: packageId,
          packageName: vulnerability.packageName,
          packageVersion: vulnerability.packageVersion,
          vulnerabilities: [vulnerability],
          exploitable: vulnerability.exploitable,
          exploit_path: exploitPackages[packageId]?.exploit_path,
          Critical: 0,
          High: 0,
          Medium: 0,
          Low: 0,
          Negligible: 0,
          Unknown: 0,
        };
      }

      packages[packageId][vulnerability.severity] += 1;
    }

    packages = Object.values(packages);

    // Sort by exploitable, critical, high, medium, low, negligible, unknown
    packages.sort((a, b) => {
      if (a.exploitable !== b.exploitable) {
        return b.exploitable - a.exploitable;
      }

      if (a.Critical !== b.Critical) {
        return b.Critical - a.Critical;
      }

      if (a.High !== b.High) {
        return b.High - a.High;
      }

      if (a.Medium !== b.Medium) {
        return b.Medium - a.Medium;
      }

      if (a.Low !== b.Low) {
        return b.Low - a.Low;
      }

      if (a.Negligible !== b.Negligible) {
        return b.Negligible - a.Negligible;
      }

      if (a.Unknown !== b.Unknown) {
        return b.Unknown - a.Unknown;
      }

      return a.packageName.localeCompare(b.packageName);
    });

    const { exploitable, notexploitable } = packages.reduce(
      (acc, pkg) => {
        if (pkg.exploitable) {
          acc.exploitable.push(pkg);
        } else {
          acc.notexploitable.push(pkg);
        }
        return acc;
      },
      { exploitable: [], notexploitable: [] }
    );

    return [exploitable, notexploitable];
  }, [vulnerabilities, exploitPackages]);

  if (vulnerabilities === null) {
    return (
      <Flex style={{ height: "100%" }}>
        <LoadingScreen />
      </Flex>
    );
  }

  return (
    <div style={styles.PackagesView}>
      <PackageViewHeader />
      {exploitable?.map((pkg) => (
        <PackageView key={pkg.id} pkg={pkg} />
      ))}
      <br />
      <div style={styles.PackageHeader}>
        <div style={styles.PackageName}>
          <b>Not Exploitable Packages:</b>
        </div>
      </div>
      {unexploitable?.map((pkg) => (
        <PackageView key={pkg.id} pkg={pkg} />
      ))}
    </div>
  );
}

export function PackageViewHeader() {
  return (
    <div style={styles.PackageHeader}>
      <div style={styles.PackageName}>
        <b>Exploitable Packages</b>
      </div>
      <SeverityView style={styles.Severity} severity="Unknown" />
      <SeverityView style={styles.Severity} severity="Negligible" />
      <SeverityView style={styles.Severity} severity="Low" />
      <SeverityView style={styles.Severity} severity="Medium" />
      <SeverityView style={styles.Severity} severity="High" />
      <SeverityView style={styles.Severity} severity="Critical" />
    </div>
  );
}

export function PackageView({ pkg }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const style = useMemo(() => {
    return {
      ...styles.PackageViewWrapper,
      ...(pkg.exploitable ? styles.Exploitable : styles.Unexploitable),
    };
  }, [pkg.exploitable]);

  const sortedVulnerabilities = useMemo(() => {
    const vulnerabilities = [...pkg.vulnerabilities];
    // Sort by severity, then by package name
    vulnerabilities.sort((a, b) => {
      if (a.severity !== b.severity) {
        return SEVERITY_RANK[b.severity] - SEVERITY_RANK[a.severity];
      }

      return a.packageName.localeCompare(b.packageName);
    });

    return vulnerabilities;
  }, [pkg.vulnerabilities]);

  const highestSeverity = useMemo(() => {
    return sortedVulnerabilities.length > 0
      ? sortedVulnerabilities[0].severity
      : "Unknown";
  }, [sortedVulnerabilities]);

  return (
    <div style={style}>
      <div style={styles.PackageView} onClick={toggleOpen}>
        <div style={styles.PackageName}>
          <b>{pkg.packageName}</b>@{pkg.packageVersion}
        </div>
        <SeverityView
          style={styles.Severity}
          severity="Unknown"
          count={pkg.Unknown}
        />
        <SeverityView
          style={styles.Severity}
          severity="Negligible"
          count={pkg.Negligible}
        />
        <SeverityView style={styles.Severity} severity="Low" count={pkg.Low} />
        <SeverityView
          style={styles.Severity}
          severity="Medium"
          count={pkg.Medium}
        />
        <SeverityView
          style={styles.Severity}
          severity="High"
          count={pkg.High}
        />
        <SeverityView
          style={styles.Severity}
          severity="Critical"
          count={pkg.Critical}
        />
      </div>
      {isOpen && (
        <>
          <ExploitPathView
            path={pkg.exploit_path}
            highestSeverity={highestSeverity}
          />
          {sortedVulnerabilities.map((vulnerability) => (
            <VulnerabilityView
              key={`${vulnerability.packageName}-${vulnerability.packageVersion}-${vulnerability.cve}`}
              vulnerability={vulnerability}
            />
          ))}
        </>
      )}
    </div>
  );
}

const styles = {
  PackagesView: {
    flex: 1,
  },
  PackageViewWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#4c515e",
    marginBottom: "0.5rem",
    borderRadius: "0.25rem",
    cursor: "default",
  },
  PackageView: {
    display: "flex",
    flexDirection: "row",
    padding: "0.5rem",
    alignItems: "center",
  },
  PackageHeader: {
    display: "flex",
    flexDirection: "row",
    padding: "0.5rem",
    marginBottom: "0.5rem",
    borderRadius: "0.25rem",
    alignItems: "center",
  },
  PackageName: {
    flex: 1,
  },
  Unexploitable: {
    opacity: 0.3,
  },
  Severity: {
    marginLeft: "0.5rem",
  },
};
