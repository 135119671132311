import React from "react";
import { SEVERITY_COLOR } from "./severity-view";
import { Tooltip } from "@mui/material";

export const ExploitPathView = React.memo(({ path, highestSeverity }) => {
  if (!path || path.length === 0) {
    return null;
  }

  return (
    <>
      <Tooltip title="This view shows only one exploitation path although multiple ones might be available.">
        <div style={styles.ExploitPathTitle}>
          <i>Simplified package exploitation path</i>
        </div>
      </Tooltip>
      <div style={styles.ExploitPathView}>
        {path.map((step, index) => (
          <div key={step} style={styles.PathItem}>
            <div style={styles.PathItemVisWrap}>
              <div
                style={{
                  ...styles.PathItemVis,
                  backgroundColor:
                    index === 0 ? SEVERITY_COLOR[highestSeverity] : "#a0a0a0",
                }}
              />
              {index === 0 ? null : <div style={styles.ConnectorBottom} />}
              {index === path.length - 1 ? null : (
                <div style={styles.ConnectorTop} />
              )}
            </div>
            <div style={styles.PathItemText}>{step.replace("/", "")}</div>
          </div>
        ))}
      </div>
    </>
  );
});

const styles = {
  ExploitPathView: {
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "flex-end",
    backgroundColor: "#4c515e",
    marginBottom: "0.5rem",
    borderRadius: "0.25rem",
    cursor: "default",
    padding: "1rem 0",
    overflowX: "auto",
  },
  ExploitPathTitle: {
    marginTop: "1rem",
    fontSize: ".8rem",
    fontWeight: "500",
    color: "#a0a0a0",
    padding: "0 0.5rem",
  },
  PathItem: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    height: "2rem",
    alignItems: "center",
    padding: "0 0.5rem",
  },
  PathItemVisWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  PathItemVis: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",
    backgroundColor: "#313640",
    border: "4px solid #313640",
    display: "block",
    zIndex: 1,
  },
  PathItemText: {
    fontSize: "0.8rem",
    color: "#a0a0a0",
    fontWeight: "bold",
    marginLeft: "0.5rem",
  },
  ConnectorBottom: {
    position: "absolute",
    left: "calc(50% - 1.5px)",
    bottom: "0",
    height: "50%",
    width: "3px",
    display: "block",
    backgroundColor: "#313640",
  },
  ConnectorTop: {
    position: "absolute",
    left: "calc(50% - 1.5px)",
    top: "0",
    height: "50%",
    width: "3px",
    display: "block",
    backgroundColor: "#313640",
  },
};
