import React, { useCallback } from "react";
import icon from "../resources/logo-white.png";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { Tooltip } from "@mui/material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

export function WithMenu({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    signOut(getAuth());
  }, []);

  const handleRedirect = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <div style={styles.Wrap}>
      <div style={styles.Menu}>
        <Item disabled>
          <img src={icon} style={styles.Icon} alt="logo" />
        </Item>
        <Item
          selected={location.pathname === "/"}
          disabled={location.pathname === "/"}
          onClick={() => handleRedirect("/")}
        >
          <HomeIcon />
        </Item>
        <Item
          selected={location.pathname === "/registries"}
          disabled={location.pathname === "/registries"}
          onClick={() => handleRedirect("/registries")}
        >
          <AppRegistrationIcon />
        </Item>
        <div style={{ flex: 1 }} />
        <Item
          selected={location.pathname === "/settings"}
          disabled={location.pathname === "/settings"}
          onClick={() => handleRedirect("/settings")}
        >
          <SettingsIcon />
        </Item>
        <Item onClick={handleLogout}>
          <LogoutIcon style={{ transform: "rotate(180deg)" }} />
        </Item>
      </div>
      <div style={styles.Content}>{children}</div>
    </div>
  );
}

export function Item({ children, disabled, selected, onClick }) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      style={{
        ...styles.Item,
        ...(isHovered ? styles.ItemHover : {}),
        ...(selected ? styles.ItemSelected : {}),
      }}
      onMouseEnter={() => !disabled && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => !disabled && onClick && onClick()}
    >
      {children}
    </div>
  );
}

const styles = {
  Wrap: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  Menu: {
    width: "3rem",
    minWidth: "3rem",
    height: "100%",
    borderRight: "1px solid #444",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  Content: {
    flex: 1,
    display: "flex",
  },
  Item: {
    width: "100%",
    height: "3rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  ItemHover: {
    backgroundColor: "#444",
    cursor: "pointer",
  },
  ItemSelected: {
    backgroundColor: "#444",
  },
  Icon: {
    width: "1.8rem",
  },
};
